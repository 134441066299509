import { Grid } from '@mui/material';
import {
    BooleanInput,
    Button,
    Create,
    FormDataConsumer,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useCreateSuggestionContext,
    useNotify,
    useRedirect,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import CustomRichTextToolbar from '../../components/enhancedmessaging/CustomRichTextToolbar';
import SendTestMessage from '../../components/enhancedmessaging/SendTestMessage';
import { convert } from 'html-to-text';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';

const transform = (data:any) => {

    // remove links from sms_message only keeps the text of the a tag
    const sms_message = convert(data.sms_message.replace(/<a[^>]*>(.*?)<\/a>/g, '$1'), {
        wordwrap: false,
    }) // convert html to text
        .replace(/[\n]{2}/g, '\n') // fix duplicate newlines
        .replace(/[\n]{2,}/g, '\n\n') // no more than one empty line
        .trim() // no leading/trailing newlines or spaces

    data = {
        ...data,
        is_broadcast: false,
        sms_message: sms_message,
        practice: localStorage.getItem('selectedPractice') || '',
    }

    // default for no specific type
    if (!data.event_type) data.event_type = "General";

    if (!data.capture_response) data.response_type = "None";

    // remove test message fields
    delete data.test_sms;
    delete data.test_email;
    return data;
};

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
};

const CustomToolbar = ({ onCancel }) => {
    return <Toolbar style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
        <Button
            label='Cancel'
            size='medium'
            variant='contained'
            onClick={onCancel}
        />
        <SaveButton />
    </Toolbar>
}

const editorOptions = { // Disable automatic URL linking
    extensions: [
        StarterKit,
        Link.configure({ autolink: false }),
        Image
    ]
}

export const MessageTemplateCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (record: any) => {
        notify('Template created successfully');
        redirect('/messagetemplates');
    }

    const onCancel = (data: any) => {
        redirect('/messagetemplates');
    }

    return <MessageTemplateForm onCancel={onCancel} onSuccess={onSuccess} />
};

export const MessageTemplateCreateModal = () => {
    const { onCancel, onCreate } = useCreateSuggestionContext();

    const onSuccess = (record: any) => {
        onCreate({ id: record.id, name: record.name })
    }

    return <MessageTemplateForm onCancel={onCancel} onSuccess={onSuccess} />
};

const MessageTemplateForm = ({ onCancel, onSuccess }) => {
    return (
        <Create
            className='template-create'
            sx={{ maxWidth: "1020px", width: "100%" }}
            transform={transform}
            mutationOptions={{ onSuccess }}
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className="template-form"
                toolbar={<CustomToolbar onCancel={onCancel} />}
            >
                <h1>Create Template</h1>
                <Grid {...gridProps}>
                    <p className="template-form-label">
                        1. Name your template:
                    </p>
                    <Grid item xs={12}>
                        <TextInput source='name' validate={[required()]} fullWidth />
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className="template-form-label">
                        2. Provide the type of template:
                    </p>
                    <Grid item xs={12}>
                        <SelectInput
                            sx={{maxWidth: '50%'}}
                            fullWidth
                            source='event_type'
                            optionValue='name'
                            emptyValue='General'
                            choices={[
                                { id: '1', name: 'Appointments' },
                                { id: '8', name: 'Appointment Confirmation' },
                                { id: '10', name: 'Appointment Survey' },
                                { id: '3', name: 'Birthday' },
                                { id: '9', name: 'HEP Survey' },
                                { id: '4', name: 'Message Received' },
                                { id: '5', name: 'Opt-In' },
                                { id: '7', name: 'Rating' },
                                { id: '2', name: 'Report Pain' },
                                { id: '6', name: 'RTM Code' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BooleanInput source='capture_response' label='Capture a response from the patient to use in an automation' />
                    </Grid>

                    <FormDataConsumer>
                        {({ formData }) => {
                            if (formData.capture_response) {
                                const responseTypes = [{ id: '1', name: 'Rating' }];
                                if (formData.event_type === 'Appointments') {
                                    responseTypes.unshift({ id: '2', name: 'Confirmation'});
                                }
                                return (
                                    <Grid item xs={12} sx={{ paddingLeft: '42px' }}>
                                        <SelectInput
                                            sx={{maxWidth: '50%'}}
                                            fullWidth
                                            source='response_type'
                                            optionValue='name'
                                            choices={responseTypes}
                                            validate={[
                                                required(),
                                                () => {
                                                    if (formData.response_type === 'Confirmation' &&
                                                        formData.event_type !== 'Appointments') return 'Required';
                                                    return undefined;
                                                }
                                            ]}
                                        />
                                    </Grid>
                                );
                            }
                        }}
                    </FormDataConsumer>
                </Grid>

                <Grid {...gridProps} className="template-form-richtext">
                    <p className="template-form-label">
                        3. Provide your text message:
                    </p>
                    <Grid item xs={12}>
                        <RichTextInput
                            source='sms_message'
                            fullWidth
                            validate={[required()]}
                            toolbar={<CustomRichTextToolbar richTextMode={false} />}
                            editorOptions={editorOptions}
                        />
                    </Grid>
                </Grid>

                <Grid {...gridProps} className="template-form-richtext">
                    <p className="template-form-label">
                        4. Provide your rich text message (optional, used for emails):
                    </p>
                    <Grid item xs={12}>
                    <p>Email Subject</p>
                        <RichTextInput
                            id='email-subject'
                            source="email_subject"
                            fullWidth
                            helperText = "Enter an email subject - dynamic text supported"
                            toolbar={<CustomRichTextToolbar richTextMode={false} />}
                            editorOptions={editorOptions}
                            parse={v => convert(v.replace(/<a[^>]*>(.*?)<\/a>/g, '$1'), { wordwrap: false })} // strip html off subject
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RichTextInput
                            source='email_message'
                            fullWidth
                            toolbar={<CustomRichTextToolbar richTextMode={true} />}
                            editorOptions={editorOptions}
                        />
                    </Grid>
                </Grid>
                <SendTestMessage origin='messagetemplates'/>
            </SimpleForm>
        </Create>
    );
};
