import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MailIcon from '@mui/icons-material/MailOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
    endOfToday,
    addWeeks,
    addMonths,
    endOfMonth,
    endOfWeek,
} from 'date-fns';

import segments from '../../segments/data';
import { subDays } from 'date-fns';
console.log(segments);

const Aside = () => {

    return (

        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 16em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                {/* bring them back in when name is available */}
                {/* <FilterLiveSearch source="id" label="Name" /> */}
                {/* <FilterLiveSearch source="first" label="User First Name" />

                <FilterLiveSearch source="last" label="User Last Name" /> */}

                <FilterList
                    label="MESSAGE SENT"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="Today"
                        value={{
                            updatedAt: {
                                ">=": Math.round(new Date(endOfYesterday()).getTime()),
                                "<=": Math.round(new Date(endOfToday()).getTime()),
                            },
                        }}
                    />
                    <FilterListItem
                        label="This week"
                        value={{
                            updatedAt: {
                                ">=": Math.round(new Date(subDays(startOfWeek(new Date()), 1)).getTime()),
                                "<=": Math.round(new Date(endOfWeek(new Date())).getTime()),
                            },
                        }}
                    />
                    <FilterListItem
                        label="Last week"
                        value={{
                            updatedAt: {
                                ">=": Math.round(new Date(subWeeks(subDays(startOfWeek(new Date()), 1), 1)).getTime()),
                                "<=": Math.round(new Date(subDays(startOfWeek(new Date()), 1)).getTime()),
                            },
                        }}
                    />
                    <FilterListItem
                        label="This month"
                        value={{
                            updatedAt: {
                                ">=": Math.round(new Date(subDays(startOfMonth(new Date()), 1)).getTime()),
                                "<=": Math.round(new Date(endOfMonth(new Date())).getTime()),
                            },
                        }}
                    />
                    <FilterListItem
                        label="Last month"
                        value={{
                            updatedAt: {
                                ">=": Math.round(new Date(subMonths(subDays(startOfMonth(new Date()), 1), 1)).getTime()),
                                "<=": Math.round(new Date(subDays(startOfMonth(new Date()), 1)).getTime()),
                            },
                        }}
                    />
                    <FilterListItem
                        label="Earlier"
                        value={{
                            updatedAt: { "<=": Math.round(new Date(subMonths(subDays(startOfMonth(new Date()), 1), 1)).getTime()) },
                        }}
                    />
                </FilterList>

                <FilterList
                    label="TYPE"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="Conditions"
                        value={{
                            type:"firstTrue"
                        }}
                    />
                    <FilterListItem
                        label="Event"
                        value={{
                            type: "event"
                        }}
                    />
                    <FilterListItem
                        label="Reminder/Missed"
                        value={{
                            type: "appointment"
                        }}
                    />
                    <FilterListItem
                        label="Scheduled"
                        value={{
                            type: "time"
                        }}
                    />
                    <FilterListItem
                        label="Instant"
                        value={{
                            type: "now"
                        }}
                    />


                </FilterList>

                <FilterList
                    label="Channel"
                    icon={<MailIcon />}
                >
                    <FilterListItem
                        label="Text"
                        value={{
                            channel: "sms",
                        }}
                    />     <FilterListItem
                        label="Email"
                        value={{
                            channel: "email",
                        }}
                    />
                </FilterList>

                <FilterList
                    label="RESULT"
                    icon={<MailIcon />}
                >
                    <FilterListItem
                        label="Success"
                        value={{
                            result: "success",
                        }}
                    />
                    <FilterListItem
                        label="Failure"
                        value={{
                            result: "failure",
                        }}
                    />
                    <FilterListItem
                        label="Suppressed"
                        value={{
                            result: "suppressed",
                        }}
                    />
                </FilterList>

                <FilterList
                    label="EVENT"
                    icon={<LocalOfferIcon />}
                >
                    <FilterListItem
                        label="Opt-In"
                        value={{
                            result: "false0",
                        }}

                    />
                    <FilterListItem
                        label="Check-In"
                        value={{
                            result: "false1",
                        }}
                    />
                    <FilterListItem
                        label="Report Pain"
                        value={{
                            result: "false2",
                        }}
                    />
                    <FilterListItem
                        label="Exercises Complete"
                        value={{
                            result: "false3",
                        }}
                    />
                    <FilterListItem
                        label="Birthday"
                        value={{
                            result: "false4",
                        }}
                    />
                </FilterList>


            </CardContent>
        </Card>
    )
};

export default Aside;
