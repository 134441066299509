import { Grid } from '@mui/material';
import {
    BooleanInput,
    Button,
    Edit,
    FormDataConsumer,
    RecordContext,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import CustomRichTextToolbar from '../../components/enhancedmessaging/CustomRichTextToolbar';
import SendTestMessage from '../../components/enhancedmessaging/SendTestMessage';
import { convert } from 'html-to-text';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';

const transform = (data: any) => {

    // remove links from sms_message only keeps the text of the a tag
    const sms_message = convert(data.sms_message.replace(/<a[^>]*>(.*?)<\/a>/g, '$1'), {
        wordwrap: false,
    }) // convert html to text
        .replace(/[\n]{2}/g, '\n') // fix duplicate newlines
        .replace(/[\n]{2,}/g, '\n\n') // no more than one empty line
        .trim() // no leading/trailing newlines or spaces


  data = {
    ...data,
    sms_message: sms_message,
    practice: data.practice.id,
  };

  // default for no specific type
  if (!data.event_type) data.event_type = "General";

  if (!data.capture_response) data.response_type = "None";

  // remove test message fields
  delete data.test_sms;
  delete data.test_email;
  return data;
};

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
};

const editorOptions = { // Disable automatic URL linking
    extensions: [
        StarterKit,
        Link.configure({ autolink: false }),
        Image
    ]
}

const MessageTemplateEdit = () => {
    return (
        <Edit
            className='template-edit'
            sx={{ maxWidth: "1020px" }}
            transform={transform}
            mutationMode="pessimistic"
        >
            <RecordContext.Consumer>
                {record => {
                    // repalce newlines with <p> tags
                    let sms = record.sms_message ? record.sms_message.replaceAll('\n', '</p><p>') : '';
                    if (!sms.startsWith('<p>') && !sms.endsWith('</p>')) sms = '<p>' + sms + '</p>';
                    // no more than one consecutive empty paragraph
                    sms = sms.replace(/(<p><\/p>){2,}/g, '<p></p>');

                    const newRecord = {
                        ...record,
                        sms_message: sms,
                    }

                    return <MessageTemplateEditForm record={newRecord} />
                }}
            </RecordContext.Consumer>
        </Edit>
    );
};

const MessageTemplateEditForm = ({ record }) => {
    const redirect = useRedirect();

    const onCancel = (data: any) => {
        redirect('/messagetemplates');
    }

    const CustomToolbar = (props) => {
        return <Toolbar {...props} style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
            <Button
                label='Cancel'
                size='medium'
                variant='contained'
                onClick={onCancel}
            />
            <SaveButton />
        </Toolbar>
    }
    return (
        <SimpleForm
            sx={{ pt: 0, pb: 0 }}
            className="template-form"
            record={record}
            toolbar={<CustomToolbar />}
        >
            <h1>Edit Template</h1>
            <Grid {...gridProps}>
                <p className="template-form-label">
                    1. Name your template:
                </p>
                <Grid item xs={12}>
                    <TextInput source='name' validate={[required()]} fullWidth />
                </Grid>
            </Grid>

            <Grid {...gridProps}>
                <p className="template-form-label">
                    2. Provide the type of template:
                </p>
                <Grid item xs={12}>
                    <SelectInput
                        sx={{maxWidth: '50%'}}
                        fullWidth
                        source='event_type'
                        optionValue='name'
                        emptyValue='General'
                        choices={[
                          { id: '1', name: 'Appointments' },
                          { id: '8', name: 'Appointment Confirmation' },
                          { id: '10', name: 'Appointment Survey' },
                          { id: '3', name: 'Birthday' },
                          { id: '9', name: 'HEP Survey' },
                          { id: '4', name: 'Message Received' },
                          { id: '5', name: 'Opt-In' },
                          { id: '7', name: 'Rating' },
                          { id: '2', name: 'Report Pain' },
                          { id: '6', name: 'RTM Code' },
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput source='capture_response' label='Capture a response from the patient to use in an automation' />
                </Grid>

                <FormDataConsumer>
                    {({ formData }) => {
                        if (formData.capture_response) {
                            const responseTypes = [{ id: '1', name: 'Rating' }];
                            if (formData.event_type === 'Appointments') {
                                responseTypes.unshift({ id: '2', name: 'Confirmation'});
                            }
                            return (
                                <Grid item xs={12} sx={{ paddingLeft: '42px' }}>
                                    <SelectInput
                                        sx={{maxWidth: '50%'}}
                                        fullWidth
                                        source='response_type'
                                        optionValue='name'
                                        choices={responseTypes}
                                        validate={[
                                            required(),
                                            () => {
                                                if (formData.response_type === 'Confirmation' &&
                                                    formData.event_type !== 'Appointments') return 'Required';
                                                return undefined;
                                            }
                                        ]}
                                    />
                                </Grid>
                            );
                        }
                    }}
                </FormDataConsumer>
            </Grid>

            <Grid {...gridProps} className="template-form-richtext">
                <p className="template-form-label">
                    3. Provide your text message:
                </p>
                <Grid item xs={12}>
                    <RichTextInput
                        source='sms_message'
                        fullWidth
                        validate={[required()]}
                        toolbar={<CustomRichTextToolbar richTextMode={false} />}
                        editorOptions={editorOptions}
                    />
                </Grid>
            </Grid>

            <Grid {...gridProps} className="template-form-richtext">
                <p className="template-form-label">
                    4. Provide your rich text message (optional, used for emails):
                </p>
                <Grid item xs={12}>
                    <p>Email Subject</p>
                        <RichTextInput
                            id="email-subject"
                            source="email_subject" // need to pull existing into this
                            fullWidth
                            helperText = "Enter an email subject - dynamic text supported"
                            toolbar={<CustomRichTextToolbar richTextMode={false} />}
                            editorOptions={editorOptions}
                            parse={v => convert(v.replace(/<a[^>]*>(.*?)<\/a>/g, '$1'), { wordwrap: false })} // strip html off subject
                        />
                    </Grid>
                <Grid item xs={12}>
                    <RichTextInput
                        source='email_message'
                        fullWidth
                        toolbar={<CustomRichTextToolbar richTextMode={true} />}
                        editorOptions={editorOptions}
                    />
                </Grid>
            </Grid>
            <SendTestMessage origin='messagetemplates' />
        </SimpleForm>
    );
};

export default MessageTemplateEdit;
