import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { ChannelSort, StreamChat, logChatPromiseExecution } from 'stream-chat';
import { debounce } from 'lodash';
import {
  Chat,
  Channel,
  ChannelList,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useChannelStateContext,
  useChannelActionContext,
  DefaultStreamChatGenerics,
} from 'stream-chat-react';
import { Box, Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { grey } from '@mui/material/colors';
import Row from 'react-bootstrap/Row';
import 'stream-chat-react/dist/css/index.css';
import '../../scss/pages/EnhancedMessaging.scss';
import { CustomPreview } from './CustomPreview';
import { CustomMessage } from './CustomMessage';
import { CustomMessageInput } from './CustomMessageInput';
import { format, intervalToDuration, formatDuration } from 'date-fns';
import {
  useUpdate,
  useGetIdentity,
  useRedirect,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  AutocompleteInputProps,
  fetchUtils,
  useNotify,
} from 'react-admin';
import Modal from '@mui/material/Modal';
import { useFormContext } from 'react-hook-form';
// import { useResolveAction } from '../../resolveAction.js';
import SessionTimeout from '../../SessionTimeout';
import { logActivity } from '../../logActivity';
import './Messages.scss';
import { SecureMessageToggle } from '../../components/messages/SecureMessageToggle';
import { CustomChannelSearchResultItem } from '../../components/messages/CustomChannelSearchResultItem';
import { MessagesRecordWrapper } from '../../components/messages/MessagesRecordWrapper';
import ScheduleMessage from '../../components/messages/ScheduleMessage';
import tokenManager from '../../tokenManager';
import { Patient } from '../../types';
import moment from 'moment-timezone';
import { useSearchParams } from 'react-router-dom';
import { SavedChatMessageCreateModal } from '../savedchatmessages/Create';
import { SavedChatMessageEditModal } from '../savedchatmessages/Edit';
import { ChannelSearchFunctionParams } from 'stream-chat-react/dist/components/ChannelSearch/hooks/useChannelSearch';
import { AddByPhoneDialog } from '../../components/messages/AddByPhoneDialog';

const Messages = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const channel_id = searchParams.get("channel_id") || ""

  const selectdPractice = localStorage.getItem('selectedPractice') || ''
  const [filters, setFilters] = useState({ type: 'messaging', members: { $in: [selectdPractice] } });
  const sort: ChannelSort<DefaultStreamChatGenerics> = [{ unread_count: -1 }, {last_patient_message_timestamp: -1}, { last_message_at: -1 }];
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const [record, setRecord] = useState<Patient | null>(null);
  const [customType, setCustomType] = useState("secure");
  const [customActiveChannel, setCustomActiveChannel] = useState<string | undefined>(undefined);
  const [channelListKey, setChannelListKey] = useState(0);
  const [addByPhoneDialogOpen, setAddByPhoneDialogOpen] = useState(false)
  const [phoneDialogInputValue, setPhoneDialogInputValue] = useState('')
  const queryRef = useRef('')

  // const {} = useGetIdentity(); // causing build warnings
  const redirect = useRedirect()
  const notify = useNotify()

  const toggleCustomTypeSchedule = () => {
    setCustomType(customType === "schedule" ? "secure" : "schedule");
  };

  useEffect(() => {
    setChatClient(StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY!));
  }, []);

  useEffect(() => {
    const id = searchParams.get("channel_id") || ""
    if (id) {
      setCustomActiveChannel(`${id}`)
      setChannelListKey(channelListKey + 1)
    }
  }, [searchParams])

  const debouncedSearch = useCallback(
    debounce(async (value, setResults, setSearching, setQuery, client) => {
      if (!value) {
        setResults([])
        setQuery(value)
        return;
      }

      const filters = {
        "member.user.name": {$autocomplete: value.valueOf().toString()},
        members: { $in: [client.userID!] },
      };
      setSearching(true)
      setQuery(value)
      let channels = await client.queryChannels(filters, sort)
      setResults(channels)
      setSearching(false)
    }, 300),
    [chatClient, sort]
  )

  if (!chatClient) {
    return <LoadingIndicator />;
  }

  const handleInputChange = async (
    props: ChannelSearchFunctionParams,
    event: { target: { value: SetStateAction<string> } },
    client: StreamChat,
  ) => {
    const { setResults, setSearching, setQuery } = props;
    const value = event.target.value;
    setQuery(value)
    queryRef.current = value.toString()

    debouncedSearch(value, setResults, setSearching, setQuery, client);
  }

  const handlePhoneDialogOpen = () => {
    const numericPart = queryRef.current.replace(/\D/g, ''); // Extract only digits
    const limitedDigits = numericPart.slice(0, 10); // Limit to 10 digits
    if (limitedDigits.length <= 3) {
      setPhoneDialogInputValue(limitedDigits)
    } else if (limitedDigits.length <= 6) {
      setPhoneDialogInputValue(`(${limitedDigits.slice(0, 3)}) ${limitedDigits.slice(3)}`)
    } else {
      setPhoneDialogInputValue(`(${limitedDigits.slice(0, 3)}) ${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6, 10)}`)
    }
    setAddByPhoneDialogOpen(true)
  }

  const CustomFooter = () => (
    <Box padding={2} borderTop="1px solid #ddd" textAlign="center">
      <Typography 
        sx={{ fontSize: 'medium', fontWeight: 'bold' }}
        color="textSecondary" 
        display="block"
        gutterBottom
      >
        Couldn't find what you’re looking for?
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <Button
          variant="text"
          size="large"
          onClick={handlePhoneDialogOpen}
          sx={{ textTransform: 'none', padding: 0, minWidth: 0 }}
        >
          Click here to find/create a channel by phone number.
        </Button>
      </Typography>
    </Box>
  );
  
  const CustomSearchResultList = (props) => {
    const { results=[], selectResult, query, searching } = props;
  
    return (
      <div className="str-chat__channel_search_container str-chat__search-result-list inline">
        {results.map((result) => {
          return (<CustomChannelSearchResultItem
            key={result.id}
            result={result}
            index={result.index}
            focusedUser={result.focusedUser}
            selectResult={selectResult}
          />)
        })}
        {localStorage.getItem('enhancedMessagingEnabled') === 'true' && <CustomFooter />}
      </div>
    );
  };

  return (
    <div className={`chat-container${localStorage.getItem('enhancedMessagingEnabled') === 'true' ? '-' + customType : ''}`}>
      <SessionTimeout isAuthenticated={true} logOut={() => { redirect('/users') }} />
      <Chat client={chatClient} theme='messaging light' >
        <Row>
          <AddByPhoneDialog open={addByPhoneDialogOpen} setOpen={setAddByPhoneDialogOpen} phone={phoneDialogInputValue} setPhone={setPhoneDialogInputValue} />
          <ChannelList
            key={channelListKey}
            filters={filters}
            sort={sort}
            showChannelSearch
            Preview={CustomPreview}
            //ChannelSearch={CustomChannelSearch}
            customActiveChannel={customActiveChannel}
            onMessageNewHandler={(setChannels, event) => {
              const { message, cid } = event

              if (message?.user?.id === localStorage.getItem('selectedPractice')) {
                return;
              }

              setChannels((prevChannels) => {
                const channelIndex = prevChannels.findIndex((c) => c.cid === cid);
                if (channelIndex > -1) {
                  const updatedChannels = [...prevChannels];
                  const [movedChannel] = updatedChannels.splice(channelIndex, 1);
                  updatedChannels.unshift(movedChannel);
                  return updatedChannels;
                } else {
                  chatClient.queryChannels({ cid }).then((channels) => {
                    if (channels.length > 0) {
                      const newChannel = channels[0]
                      setChannels((currentChannels) => [newChannel, ...currentChannels])
                    }
                  })
                }
                return prevChannels;
              })
            }}
            additionalChannelSearchProps={{
              searchFunction: (params, event) => {
                return handleInputChange(params, event, chatClient);
              },
              SearchResultItem: CustomChannelSearchResultItem,
              searchForChannels: true,
              popupResults: true,
              placeholder: "Search by name or phone number",
              SearchResultsList: CustomSearchResultList,
              SearchEmpty: () => (<div className="str-chat__channel-search-empty"><span>No results found</span>{localStorage.getItem('enhancedMessagingEnabled') === 'true' && <CustomFooter />}</div>)
            }}
          />
          <Channel Message={CustomMessage} Input={CustomMessageInput} >
            <Window>
              {/* <ChannelHeader /> */}
              <CustomChannelHeader customType={customType} toggleScheduleOnClick={toggleCustomTypeSchedule} record={record} setRecord={setRecord} />
              <MessageList />

              <CustomMessageBoxInput customType={customType} setCustomType={setCustomType} toggleScheduleOnSubmit={toggleCustomTypeSchedule} record={record} />
            </Window>
            <Thread />
          </Channel>
        </Row>

      </Chat>
    </div>
  );
};

const CustomChannelHeader = (
  { customType, toggleScheduleOnClick, record, setRecord }
  // , props: ChannelHeaderProps
) => {

  const { channel } = useChannelStateContext();

  var currentCustomType = customType;

  const { identity, isLoading: identityLoading } = useGetIdentity();
  const currentChannelPatient = Object.values(channel.state.members).find((user) => (
    user.user!.id !== localStorage.getItem('selectedPractice')
  ));

  const [seconds, setSeconds] = useState(0);
  const [formattedSeconds, setFormattedSeconds] = useState("00:00");

  // const record = useRecordContext<Patient>();
  const [update] = useUpdate();

  // TODO need to change the ID to be dynamic - get the current user
  // var record = useGetOne('users', { id: "63208add245335649839c790" }).data; 
  // TODO below is correct implementation
  //var record = useGetOne('users', { id: currentChannelPatient?.user_id }).data;
  // console.log(record);

  // add new activity log
  const updateWithNewRecord = useCallback((newActivity:any, record:any) => {
    if (record) {
      //date for automated testing, 0 if not testing
      var date = Number(localStorage.getItem('testDate'))
      if (!date) date = 0;

      // console.log(newActivity)
      if (newActivity.duration <= 1) return;

      var logs: any[] = [];
      var currDate = record.start_billing_date;
      var currTime = record.start_billing_time;
      var currBillingDuration = record.current_billing_duration;

      record.activity_log.map((val, index) => (
        logs.push(val)
      ))

      if (currDate == null ||
        currDate === "" ||
        currTime == null ||
        currTime === "") {
        currDate = format(new Date(), 'yyyy-MM-dd');
        currTime = format(new Date(), 'HH:mm');
      }

      if (currBillingDuration == null ||
        currBillingDuration === 0) {
        currBillingDuration = newActivity.duration
      } else {
        currBillingDuration = record.current_billing_duration + newActivity.duration
      }

      // add to the beginning of array - the order of these activities are in the same order as displayed on activity log table ... bottom to top
      logs = [newActivity, ...logs];
      const diff = {
        activity_log: logs,
        current_billing_duration: currBillingDuration,
        start_billing_date: currDate,
        start_billing_time: currTime,
      };

      update(
        'users',
        { id: record.id, data: diff, previousData: record }
      )

      logActivity("PROVIDER.View.Messages", newActivity.activity, newActivity.comment, record.id, newActivity.duration, date)
    }
  },[update]);

  // Add activity log when moving away from Patient Message
  useEffect(
    () => {
      return () => {
        const startTime = new Date();
        const duration = Math.round((new Date().getTime() - startTime.getTime()) / 1000);
        const newActivity = {
          "date": Math.round(startTime.getTime() / 1000),
          "duration": duration,
          "activity": "Viewed Patient Message",
          "first": identity?.name ?? "",
          "last": "", // TODO get lastname
          "email": identity?.email ?? "",
          "picture": identity?.picture ?? "",
          "comment": "None",
          "billable": false // TODO check to see if the current viewing period is billable
        };
        updateWithNewRecord(newActivity, record);
      }
    },
    [!identityLoading, record, identity?.email, identity?.name, identity?.picture, updateWithNewRecord]
  );

  useEffect(() => {
    setTimeout(() => setSeconds(seconds + 1), 1000);
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    const zeroPad = (num) => String(num).padStart(2, "0");
    const fSeconds = formatDuration(duration, {
      format: seconds >= 3600 ? ["hours", "minutes", "seconds"] : ["minutes", "seconds"],
      zero: true,
      delimiter: ":",
      locale: {
        formatDistance: (_token, count) => zeroPad(count)
      }
    });
    setFormattedSeconds(fSeconds);
  }, [seconds]);

  return (
    <div className='str-chat__header custom-header'>
      {!(channel && channel.data && channel.data.unknown_user) && <MessagesRecordWrapper setRecord={setRecord} currentChannelPatient={currentChannelPatient} />}
      <div className='top-info-container'>
        <div><span className='t-grey'>To:  </span>{currentChannelPatient?.user?.name}</div>
        {!(channel && channel.data && channel.data.unknown_user) && <div><span className='t-grey'>Timer:  </span>{formattedSeconds}</div>}
        <div className='schedule-appointment' >
          <div className='schedule-appointment'><Button onClick={() => channel.markRead()}>Mark as Read</Button></div>
          {(!(channel && channel.data && channel.data.unknown_user) && (record && record.status !== 'discharged')) ? <div className='schedule-appointment'><Button onClick={() => toggleScheduleOnClick()}><div className={currentCustomType === "schedule" ? 'minus' : 'plus'}></div>Schedule Appointment</Button></div> : <div />}
        </div>
      </div>
      {/*    this is commented out for now because resolving patient actions here was not logging the activity 
      {record?.patient_actions.length > 0 ? 
        <div className="checkboxes-container">
          <span className='t-grey'>Click to Resolve Patient Actions: </span> <ResolveActionCheckboxes record={record}/>
        </div> : <div></div>
      }  */}
    </div>
  );
};



const getSavedMessage = async ({
  currentChannelPatient,
  savedChatMessageRecord,
  userRecord,
  setSavedChatMessage,
  setMessageInputKey,
  setSavedChatMessageRecord,
  messageInputKey,
  notify
}) => {

  if (!currentChannelPatient || !currentChannelPatient.user_id || currentChannelPatient.user_id !== userRecord.id) {
    if (savedChatMessageRecord.message.includes('{') && savedChatMessageRecord.message.includes('}')) {
      notify(
        'Dynamic text replacement is not available in this chat channel because the associated phone number appears on multiple patient records',
        {
          type: 'info',
          autoHideDuration: 10000
        }
      )
    }
    setSavedChatMessage(savedChatMessageRecord.message)
    setMessageInputKey(messageInputKey + 1)
    return
  }

  let apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
  apiUrl += '/kaizenovate/provider/1.0.0/use-saved-message';
  const httpClient = fetchUtils.fetchJson;
  const accessToken = tokenManager.getToken();

  apiUrl += `?practice_id=${localStorage.getItem('selectedPractice') || ''}&saved_message_id=${savedChatMessageRecord.id}&user_id=${userRecord.id}`

  const response = await httpClient(`${apiUrl}`, {
    method: 'GET',
    credentials: 'include',
    user: {
        authenticated: true,
        token: `Bearer ${accessToken}`,
    }
  }).catch((e)=> {
      console.log(`Error in SavedChatMessageDropdown.handleChange()`)
      console.error(e)
      notify('Unable to replace dynamic text', {type: 'error'})
      setSavedChatMessage(savedChatMessageRecord.message)
      setMessageInputKey(messageInputKey + 1)
      return
  })

  if (response && response.body && JSON.parse(response.body).message) {
    //console.log("response", response)
    // notify('Message schedueled', {type: 'info'})
    setSavedChatMessage(JSON.parse(response.body).message)
    setSavedChatMessageRecord(savedChatMessageRecord)
    setMessageInputKey(messageInputKey + 1)
  } else {
    notify('Unable to replace dynamic text', {type: 'error'})
    setSavedChatMessage(savedChatMessageRecord.message)
    setMessageInputKey(messageInputKey + 1)
  }
}

const SavedChatMessageDropDown = ({
  savedChatMessage,
  setSavedChatMessage,
  setSavedChatMessageRecord,
  setResetSavedChatMessage,
  userRecord,
  setMessageInputKey,
  messageInputKey,
  currentChannelPatient
}) => {
  const notify = useNotify()
  const handleChange: AutocompleteInputProps['onChange'] = async (
    value,
    savedChatMessageRecord
  ) => {
    if (savedChatMessageRecord && savedChatMessageRecord.message) {
      if (savedChatMessageRecord.message !== savedChatMessage) {
        getSavedMessage({
          currentChannelPatient,
          savedChatMessageRecord,
          userRecord,
          setSavedChatMessage,
          setMessageInputKey,
          setSavedChatMessageRecord,
          messageInputKey,
          notify
        })
      }
    } else {
      // console.log("no record selected")
      setResetSavedChatMessage(true)
    }
  };
  return <ReferenceInput
    source='savedchatmessage_id'
    reference='savedchatmessages'
    filter={{ practice_id: localStorage.getItem('selectedPractice') || "noSelectedPractice"}}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput
      sx={{ "& .MuiInputBase-root": { height: "38px" } }} 
      size='small'
      className='drop-down'
      optionText='name'
      label='Select a message...'
      filterToQuery={(value) => ({ name: value })}
      onChange={handleChange}
      helperText={false}
    />
  </ReferenceInput>
}

const CustomMessageBoxInput = ({ customType, toggleScheduleOnSubmit, setCustomType, record }) => {
  const { sendMessage } = useChannelActionContext();
  const { channel } = useChannelStateContext();
  // putting the sms_message text into state
  const [savedChatMessage, setSavedChatMessage] = useState("");
  const [savedChatMessageRecord, setSavedChatMessageRecord] = useState(null);
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [isSchedule, setIsSchedule] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [scheduleModalError, setScheduleModalError] = useState(false)
  const [resetSavedChatMessage, setResetSavedChatMessage] = useState(false);
  const [messageInputKey, setMessageInputKey] = useState(0)
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const notify = useNotify();
  const currentChannelPatient = Object.values(channel.state.members).find((user) => (
    user.user!.id !== localStorage.getItem('selectedPractice')
  ));

  useEffect(() => {
    if (localStorage.getItem('enhancedMessagingEnabled') !== 'true') {
      setCustomType("secure")
    } else if ((channel && channel.data && channel.data.unknown_user)) {
      setCustomType("non-secure")
    } else if (localStorage.getItem('isAdmin') === 'true' || localStorage.getItem('isBillable') === 'true') {
      setCustomType("secure")
    } else {
      setCustomType("non-secure")
    }
  }, [channel, record, setCustomType])

  useEffect(() => {
    if (isSchedule && scheduleDate && scheduleTime) {
      const scheduleBtn = document.querySelector('.str-chat__send-button') as HTMLElement
      scheduleBtn.click()
    }
  }, [isSchedule, scheduleDate, scheduleTime]);

  var currentCustomType = customType;

  const overrideSubmitHandler = (message: any) => {
    channel.markRead()

    let customMessageData = { ...message, customType: currentCustomType };
    if (currentCustomType === "schedule") toggleScheduleOnSubmit();

    if (isSchedule) {
      if (scheduleDate && scheduleTime) {
        const practiceTZ = localStorage.getItem('selectedPracticeTZ') || 'America/New_York'
        const dateTime = `${scheduleDate} ${scheduleTime}:00`
        customMessageData.schedule_date_time = moment.tz(dateTime, practiceTZ).unix()
        scheduleMessage(customMessageData)
      }
      setIsSchedule(false)
    } else {
      const sendMessagePromise = sendMessage(message, customMessageData);
      logChatPromiseExecution(sendMessagePromise, 'send message');
      setResetSavedChatMessage(true)
      setMessageInputKey(messageInputKey + 1)
    }
  };

  const ResetSavedChatMessage = () => { // workaround for conditionally triggering useFormContext
    console.log("resetting saved chat message")
    setSavedChatMessage("")
    setSavedChatMessageRecord(null)
    useFormContext().setValue('savedchatmessage_id', "")
    setResetSavedChatMessage(false)
    setMessageInputKey(messageInputKey + 1)
    return null
  }

  const scheduleMessage = async (message: any) => {
    let apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
    apiUrl += '/kaizenovate/provider/1.0.0/schedule-chat-message';
    const httpClient = fetchUtils.fetchJson;
    const accessToken = tokenManager.getToken();

    message.practice_id = localStorage.getItem('selectedPractice') || '';
    message.channel_id = channel.id;

    const textInput = document.querySelector('.str-chat__textarea') as HTMLElement
    const preSendText = textInput!.querySelector('textarea')!.value

    const response = await httpClient(`${apiUrl}`, {
      method: 'POST',
      credentials: 'include',
      user: {
          authenticated: true,
          token: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
          ...message,
      }),
    }).catch((e)=> {
        console.log(`Error in submitTestMessage()`)
        console.error(e)
        // notify('Unable to schedule message', {type: 'error'})
        return
    })

    if (response && response.status === 200) {
      console.log("response", response)
      setScheduleModalError(false)
      setScheduleModalOpen(false)
      setIsSchedule(false)
      setResetSavedChatMessage(true)
      // notify('Message schedueled', {type: 'info'})
    } else {
      setScheduleModalError(true)
      setIsSchedule(false)
      setSavedChatMessage(preSendText)
    }
    setMessageInputKey(messageInputKey + 1)
  }

  const CreateSavedMessageModal = () => {

    const customOnCancel = () => {
      setModalOpen(false)
    }

    const customOnSuccess = () => {
      notify('Saved Message created successfully');
      setModalOpen(false)
    }

    return (
        <Modal
            open={modalOpen}
            onClose={customOnCancel}
            className='messages-saved-chat-message-modal'
        >
            <SavedChatMessageCreateModal onCancel={customOnCancel} onSuccess={customOnSuccess}/>
        </Modal>
    );
  };

  const EditSavedMessageModal = ({messageRecord}) => {
    console.log("edit message record", messageRecord)

    const customOnCancelEdit = () => {
      setEditModalOpen(false)
    }

    const customOnSuccess = () => {
      notify('Saved Message updated successfully');
      getSavedMessage({currentChannelPatient, savedChatMessageRecord: messageRecord, userRecord: record, setSavedChatMessage, setMessageInputKey, setSavedChatMessageRecord, messageInputKey, notify})
      setEditModalOpen(false)
    }

    return (
      <Modal
          open={editModalOpen}
          onClose={customOnCancelEdit}
          className='messages-saved-chat-message-modal'
      >
          <SavedChatMessageEditModal record={messageRecord} onCancel={customOnCancelEdit} onSuccess={customOnSuccess}/>
      </Modal>
    )
  }

  return (
    <div className='custom-message-input-container'>
      {currentCustomType === "schedule" ? (
        <div className="schedule-appointment-tag">Schedule an Appointment</div>
      ) : ""}
      <Grid container p={0} columnSpacing={1} alignItems="center">
        <Grid item xs={12} justifyContent="flex-end">
          <SimpleForm className="form-m-p" toolbar={false} sx={{ paddingRight: '85px' }}>
            <Grid container alignItems="center" gap="10px">
              <Grid item maxWidth="500px" width="400px">
                <SavedChatMessageDropDown
                    savedChatMessage={savedChatMessage}
                    setSavedChatMessage={setSavedChatMessage}
                    setSavedChatMessageRecord={setSavedChatMessageRecord}
                    setResetSavedChatMessage={setResetSavedChatMessage}
                    setMessageInputKey={setMessageInputKey}
                    messageInputKey={messageInputKey}
                    userRecord={record}
                    currentChannelPatient={currentChannelPatient}
                />
                {resetSavedChatMessage && <ResetSavedChatMessage />}
              </Grid>
              <CreateSavedMessageModal/>
              <EditSavedMessageModal messageRecord={savedChatMessageRecord}/>
              <Grid item>
                <Button
                  startIcon={<AddIcon />}
                  sx={{ backgroundColor: grey[600] }}
                  variant="contained"
                  onClick={() => setModalOpen(true)}
                >
                  Create
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<EditIcon />}
                  sx={{ backgroundColor: grey[600] }}
                  variant="contained"
                  onClick={() => {
                    if (savedChatMessageRecord !== null) {
                      setEditModalOpen(true)
                    } else {
                      notify('Please select a message to edit', {type: 'info'})
                    }
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {(localStorage.getItem('embodiConnectEnabled') === 'true' && localStorage.getItem('isConnectUser') === 'true') ? 
              <ScheduleMessage
                setScheduleDate={setScheduleDate}
                setScheduleTime={setScheduleTime}
                setIsSchedule={setIsSchedule}
                scheduleModalOpen={scheduleModalOpen}
                setScheduleModalOpen={setScheduleModalOpen}
                scheduleModalError={scheduleModalError}
                setScheduleModalError={setScheduleModalError}
              /> : null}
            </Grid>
          </SimpleForm>
        </Grid>
      </Grid >

      <MessageInput
        overrideSubmitHandler={overrideSubmitHandler}
        grow={true}
        getDefaultValue={() => { return savedChatMessage || "" }}
        key={messageInputKey}
      />

      {localStorage.getItem('enhancedMessagingEnabled') === 'true' ?
      <Grid item xs={5}>
        <SecureMessageToggle 
          customType={customType} 
          setCustomType={setCustomType} 
          channel={channel} 
          record={record} 
        />
      </Grid> : null}
    </div >
  )
}

export default Messages;
